form {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

form h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

form::before {
  content: "";
  border-top: 4px dashed grey;
  width: 90vw;
  position: absolute;
  left: 5vw;
  margin-top: -2rem;
}

form label {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
}
