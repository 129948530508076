.App {
  margin: 2rem;
}

.container {
  margin: auto;
  max-width: 60%;
  min-width: 20rem;
}

.bottom {
  margin-top: 5rem;
  text-align: center;
  color: grey;
}

.controller {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: fixed;
  bottom: 20%;
  right: 1rem;
  z-index: 100;
  background-color: salmon;
  opacity: 0.8;
  text-align: center;
  line-height: 3rem;
}

.arrow {
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.loading {
  width: 100%;
  height: 8rem;
  line-height: 8rem;
  text-align: center;
  border: 1px solid black;
}
