.post > .header > h2 {
  width: fit-content;
  font-size: 2rem;
  margin-top: 0px;
  margin-bottom: 0px;
}

.post > .header > h2::after {
  content: "";
  display: flex;
  width: 100%;
  max-width: 60vw;
  height: 0.4rem;
  background-color: salmon;
  position: relative;
  margin-top: -0.6rem;
  z-index: -99;
}

.post {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin-top: 2rem;
  border: 1px solid black;
  min-height: 6rem;
}

.post > .header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.post > .header > .detail {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  font-weight: 700;
  opacity: 0.7;
}

.post > .image-container {
  width: 100%;
}

.image-container > img.full {
  width: 100%;
  margin: 0.5rem 0;
  object-fit: fit;
}

.image-container > img.small:hover {
  box-sizing: border-box;
  border: 0.5rem solid salmon;
  width: 100%;
  height: 8rem;
  margin: 0.5rem 0;
  border-radius: 0.8rem;
  object-fit: cover;
}

.image-container > img.small {
  width: 100%;
  height: 8rem;
  margin: 0.5rem 0;
  border-radius: 0.8rem;
  object-fit: cover;
}

.post > .like {
  line-height: 2rem;
  height: 2rem;
  width: fit-content;
  font-size: 1rem;
  text-align: left;
  vertical-align: middle;
  font-weight: 600;
}

.post > .like::before {
  content: "❤️ ";
  height: 2rem;
  font-size: 1.2rem;
}

.post > .like:hover::before {
  content: "❤️ ";
  height: 2rem;
  font-size: 1.5rem;
}

.content > h1 {
  font-size: 1.5rem;
}

.content > h2 {
  font-size: 1.2rem;
}

.content > h3 h4 h5 h6 {
  font-size: 1rem;
}

.content .image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content .image-container > img {
  width: 30rem;
  margin: auto;
  max-width: 100%;
  object-fit: fit;
}
